import './styles.scss';
var pass = "";
function storepass( )
{
    localStorage.setItem("pass", pass);
    console.log("Done");
    console.log(localStorage.getItem("pass"))
    m.redraw();
}
export default {
    view( vnode ) {
        return [
            m("form", {"style":{"    margin-top":"10px"}},
  [
    m("div", {"class":"form-group"},
      [
        m("label", {"for":"exampleInputEmail1"}, 
          "Email address"
        ),
        m("input", {"class":"form-control","type":"email","id":"exampleInputEmail1","aria-describedby":"emailHelp","placeholder":"Enter email"})
      ]
    ),
    m("div", {"class":"form-group"},
      [
        m("label", {"for":"exampleInputPassword1"}, 
          "Password"
        ),
        m("input", {"class":"form-control",type:"password",oninput:function(){pass = this.value;},"id":"exampleInputPassword1","placeholder":"Password"})
      ]
    ),
    m("div", {"class":"form-group form-check"}, 
      m("input", {"class":"form-check-input","type":"checkbox","id":"exampleCheck1"})
    ),
    m("button", {"class":"btn btn-primary","type":"submit",onclick:function(){storepass()}}, 
      "Login"
    )
  ]
)
        //    m('input',{type:"password",oninput:function(){pass = this.value;},style:"vertical-align: middle;margin: 5px 10px 5px 0;padding: 4px;background-color: #fff;border: 1px solid #ddd;"}  ),
          //  m('button' ,{onclick:function(){storepass()},type:"submit",style:"padding: 3px 10px;background-color: dodgerblue;border: 1px solid #ddd;color: white;cursor: pointer;"},"Login")
        ]
    },
};